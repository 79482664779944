<template lang="pug">
.main-wrapper.empresa-visualizar
        MostrarPaciente(:waitingPaciente='waitingPaciente' :model='modelUser' :isTitular = "selectedPai" :empresaId='this.model.id' @close="dialogUser['visible']=false" :dialogPaciente='dialogUser.visible')

        DialogHistoricoCorrentista(
            :visible='dialogHistoricoCorrentista.visible'
            @close="dialogHistoricoCorrentista['visible']=false"
            :model='dialogHistoricoCorrentista.data'
        )

        Dialog.dialogRelatorios(header='Relatórios solicitados por você.' :visible.sync='dialogRelatorios' :modal='true')
           .ta-center.my-4
               span(v-if='waitingRelatorioListar') Buscando relatórios.
               span(v-else) Estes relatórios ficam salvos no sistema por <b>24 horas</b>.
    
           ProgressBar(v-if='waitingRelatorioListar' mode="indeterminate")
           .p-grid.p-align-center(v-else)
               .p-col-12(v-if="dialogRelatorios_data && dialogRelatorios_data.length > 0")
                   DataTable(:value="dialogRelatorios_data")
                       Column(headerStyle="width: 5%;" field="id" header="ID")
                       Column(headerStyle="width: 20%; text-align: center" header="Data de Criação")
                           template(#body="{data}")
                               .ta-center
                                   span {{ formatDateToBR(data.dt_criacao) }}
                       Column(headerStyle="width: 20%; text-align: center" header="Data de Expiração")
                           template(#body="{data}")
                               .ta-center
                                   span {{ formatDateToBR(data.dt_expiracao) }}
                       Column(headerStyle="width: 5%; text-align: center" header="Tipo de Arquivo")
                           template(#body="{data}")
                               .ta-center
                                   span {{ data.ds_extensao_arquivo }}
                       Column(headerStyle="width: 10%; text-align: center" header="Situação")
                           template(#body="{data}")
                               .ta-center
                                   span {{ data.ie_situacao.ds_valor }}
                       Column(headerStyle="width: 5%; text-align: center" header="Baixar")
                           template(#body="{data}")
                               .p-inputgroup.ta-center
                                   Button(
                                        :disabled="['PR', 'ER'].includes(data.ie_situacao.ie_valor)"
                                       type="button"
                                       icon="jam jam-download"
                                       v-tooltip.top="'Fazer download'"
                                       @click="downloadRelatorio(data.ds_url_relatorio)"
                                   )
               .p-col-12.ta-center(v-else)
                   h4 Nenhum relatório solicitado nas últimas 24 horas.
               .p-col-12.ta-center
                   Button.p-button-success(
                       label='Atualizar'
                       icon='jam jam-refresh'
                       @click="openDialogRelatorios()")


        Dialog.dialogFaixa(header='Valores por faixa etária' :modal='true' :visible.sync='dialogFaixa')
            .p-grid.p-fluid.p-align-end
                .p-col-6(v-for='i in faixasEtarias' :key='i.value')
                    label.form-label {{ i.label }}:
                    CurrencyInput(
                        disabled
                        v-model='i.nr_valor'
                        locale='pt-BR'
                        :auto-decimal-mode='true'
                        :value-range='{ min: 0 }'
                    )
                .p-col-12.ta-center
                    //- ProgressSpinner(v-if='waitingInativar' strokeWidth='6')
                    Button.p-button-warning( icon='jam jam-plus-circle' label='OK' @click='dialogFaixa = false')

        Dialog.dialogApagar(header='Remover Usuário' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover o usuario <b>{{ dialogApagar_data?.nm_pessoa_fisica }}</b>?
            .ta-center.my-4
                ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='excluirCorrentista()')
        Dialog.dialogCancelar(header='Cancelar Solicitação' :visible.sync='dialogCancelar.visible' :modal='true')
            .p-grid.p-fluid
                .p-col-12
                    p Cancelar a <b>{{ dialogCancelar.data?.ie_tipo_status_f }}</b> do correntista: <b>{{ dialogCancelar.data?.associado?.nm_pessoa_fisica}}</b>?
                .p-col-12.ta-center.my-1
                    ProgressBar(v-if='waitingAprovar'  strokeWidth='6' mode='indeterminate')
                    Button.p-button-danger(v-else label='Cancelar' @click='cancelarSolicitacoesAdm()')

        Dialog.dialogHistorico(:header="`Histórico de Ativação/Inativação - ${ dialogHistorico_data.nm_pessoa_fisica }`" :visible.sync='dialogHistorico' @hide='dialogHistorico_data = {}' :modal='true')
            ProgressBar(v-if='waitingHistorico' strokeWidth='6' mode='indeterminate')
            p.ta-center.text-secondary(v-else-if='!dialogHistorico_data.list?.length' style='margin-top: 40px;') Nenhum registro encontrado.
            DataTable(v-else :value="dialogHistorico_data.list")
                Column(headerStyle='width: 25%;' header='Tipo de ação' field='ie_tipo_status' bodyStyle='text-align:center;' )
                    template(#body='{data}')
                        p {{ options.ie_tipo_status.find(item => item.value == data.ie_tipo_status)?.label }}
                Column(headerStyle='width: 25%; font-size:.9em' bodyStyle='text-align:center; ' field='dt_criado_f' header='Data')
                Column(headerStyle='width: 50%; font-size:.9em' bodyStyle='font-size:.9em; text-align:center;' field='ds_observacao' header='Observação')

        Dialog.dialogInativar(header='Inativar correntista' :modal='true' :visible.sync='dialogInativar' @hide='dialogInativar_data = {}; modelInativar = {}')
            .p-grid.p-fluid
                .p-col-12.ta-center Deseja inativar o correntista <b>{{ dialogInativar_data.nm_pessoa_fisica }}</b>?
                .p-col-12.ta-center(v-if='!dialogInativar_data.cd_titular') <i style='color:red'> A inativação deste correntista implicará na inativação dos seus possíveis dependentes.</i>

                .p-col-12
                    label.form-label Motivo de inativação:
                    Dropdown(v-model='modelInativar.ie_motivo' :options='options.ie_motivo'
                        optionLabel='label' optionValue='value' placeholder='Selecione')
                .p-col-12
                    label.form-label Observação:
                    Textarea(v-model='modelInativar.ds_observacao' rows='3' style='width:100% !important')
                .p-col-12.ta-center
                    ProgressSpinner(v-if='waitingInativar' strokeWidth='6')
                    Button.p-button-warning(v-else icon='jam jam-minus-circle' label='Confirmar' @click='inativarCorrentista()')

        Dialog.dialogAprovar(header='Aprovar solicitação' :visible.sync='dialogAprovar.visible' :modal='true')
            .p-grid.p-fluid
                .p-col-12
                    ProgressBar(v-if="waitingComprovanteAprovarSolicitacao" mode="indeterminate")
                    .p-inputgroup(v-else )
                        InputText(type="text" placeholder="Anexar comprovante" v-model="comprovanteAprovar.name"
                            @click="$refs.comprovanteAprovarSolicitacao.click()" readonly)
                        Button.p-button-warning(
                            style="margin-right: flex-end;"
                            icon="jam jam-attachment"
                            v-tooltip.top="'Adicionar comprovante'"
                            @click="$refs.comprovanteAprovarSolicitacao.click()")
                    input(
                        v-show='false'
                        type="file"
                        ref="comprovanteAprovarSolicitacao"
                        @change="anexarComprovanteAprovaSolicitacao()"
                        accept=".pdf, image/png, image/jpeg")
                .p-col-12.ta-center
                    p Aprovar a <b>{{ dialogAprovar.data?.ie_tipo_status_f }}</b> do correntista: <b>{{ dialogAprovar.data?.associado?.nm_pessoa_fisica}}</b>?<br/>
                        span(v-if='dialogAprovar.data?.ie_tipo_status == "SOE"' style='color: red') <i> A exclusão deste correntista implicará na exclusão dos seus dependentes.</i>

                .p-col-12.ta-center.my-1
                    ProgressSpinner(v-if='waitingAprovar' strokeWidth='6')
                    Button.p-button-success(v-else label='Aprovar' @click='aprovarSolicitacoesAdm()')

        Dialog.dialogAprovar(header='Trocar documento' :visible.sync='dialogDocumento.visible' :modal='true' @hide="handleCloseDialogDocument()")
            .p-grid.p-fluid
                .p-col-12
                    ProgressBar(v-if="waitingComprovanteAprovarSolicitacao" mode="indeterminate")
                    .p-inputgroup(v-else )
                        InputText(type="text" placeholder="Anexar comprovante" v-model="comprovanteDocumento.name"
                            @click="$refs.comprovanteAprovarSolicitacao.click()" readonly)
                        Button.p-button-warning(
                            style="margin-right: flex-end;"
                            icon="jam jam-attachment"
                            v-tooltip.top="'Adicionar comprovante'"
                            @click="$refs.comprovanteAprovarSolicitacao.click()")
                    input(
                        v-show='false'
                        type="file"
                        ref="comprovanteAprovarSolicitacao"
                        @change="anexarComprovanteAprovaSolicitacao()"
                        accept=".pdf, image/png, image/jpeg")

        Dialog.dialogErros(header='Erros no cadastro do arquivo' :visible.sync='dialogErros' :modal='true')
            p(style='margin-top: 0 !important;') <b>Após consertar os erros reenvie o arquivo.</b>
            p.ta-right Usuários adicionados: {{ totalAcertos }}
             p.ta-right Usuários não adicionados : {{ totalErros }}
            Panel.datatable(v-if="erros.erros_planilha.length" header='Erros na planilha' style='margin-bottom: 10px;' :toggleable	="true" :collapse = "true")
                DataTable(:value="erros.erros_planilha")
                    Column
                        template(#body='{data}')
                            p {{ data.text }}
            Panel.datatable(v-if="erros.erros_correntista.length" header='Erros de cadastro' :toggleable = "true" :collapse = "true")
                DataTable(:value="erros.erros_correntista")
                    Column
                        template(#body='{data}')
                            p {{ data.text }}

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub / Empresa / <b>Visualizar</b>

            div
                ProgressBar(v-if='waiting' mode="indeterminate")
                div(v-else)
                    Panel(header="Dados Empresa")
                        .p-grid.p-fluid.p-align-end
                            .p-col-12.p-md-3
                                label.form-label Razão social:
                                InputText(type='text' v-model='model.cd_pessoa_juridica.ds_razao_social' readonly)
                            .p-col-12.p-md-3
                                label.form-label CNPJ:
                                InputText(mask='99.999.999/9999-99' v-model='model.cd_pessoa_juridica.nr_cnpj_f' readonly)
                            .p-col-12.p-md-3
                                label.form-label Nome fantasia:
                                InputText(type='text' v-model='model.cd_pessoa_juridica.nm_fantasia' readonly)
                            .p-col-12.p-md-3
                                label.form-label Responsável:
                                InputText(type='text' v-model='model.nm_responsavel' readonly)
                            .p-col-12.p-md-3
                                label.form-label Bairro:
                                InputText(type='text' v-model='_getEndereco().nm_bairro' readonly)
                            .p-col-12.p-md-3
                                label.form-label Rua:
                                InputText(type='text' v-model='_getEndereco().nm_logradouro' readonly)
                            .p-col-12.p-md-6
                                label.form-label Complemento:
                                InputText(type='text' v-model='_getEndereco().ds_complemento' readonly)
                            .p-col-12.p-md-3
                                label.form-label CEP:
                                InputText(mask='99999-999' v-model='_getEndereco().nr_cep_f' readonly)
                            .p-col-12.p-md-3
                                label.form-label Número da Empresa:
                                InputText(type='text' v-model='_getEndereco().nr_numero' readonly)
                            .p-col-12.p-md-3
                                label.form-label Telefone:
                                InputText(:mask="'(99) 99999999?9'" v-model='_getContato().nr_telefone_f' readonly)
                            .p-col-12.p-md-3
                                label.form-label Email:
                                InputText(type='text' v-model='_getContato().ds_email' readonly)

                            .p-col-12.mt-2.mb-2(v-if="model.id")
                                .p-grid
                                    .p-col-12.p-md-6.p-lg-3
                                        span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                    .p-col-12.p-md-6.p-lg-3
                                        span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                    .p-col-12.p-md-6.p-lg-3
                                        span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                    .p-col-12.p-md-6.p-lg-3
                                        span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}
                Panel.datatable.mt-3(v-if='modelContrato' header="Dados de contrato" :toggleable = "true" :collapsed='true')
                    .ta-left(v-if='modelContrato.aq_anexo')
                        Button.p-button-success(
                            style="margin-right: flex-end;max-width:20%;"
                            icon="jam jam-download"
                            label="Abrir Contrato"
                            @click="downaloadContrato()")
                    .p-grid.p-fluid.p-align-start
                        .p-col-12.p-md-12
                            label.form-label Descrição do contrato:
                            Textarea.p-disabled(type='text' disabled v-model='modelContrato.ds_contrato'
                                style='width: 100%; padding: 5px'
                                :autoResize="true"
                                rows="4"
                            )

                        .p-col-12.p-md-4
                            label.form-label Nome do responsável:
                            InputText(type='text' disabled v-model='modelContrato.nm_responsavel')

                        .p-col-12.p-md-4
                            label.form-label CPF do responsável:
                            InputMask.text-readonly( type='tel' disabled v-model='modelContrato.nr_cpf' mask='999.999.999-99')


                        .p-col-12.p-md-4
                            label.form-label Telefone:
                            InputMask( type='tel' disabled v-model='modelContrato.nr_telefone' mask='(99) 99999999?9')


                        .p-col-12.p-md-4
                            label.form-label Data Inicial de Vigência:
                            .p-inputgroup
                                Calendar( disabled v-model='modelContrato.dt_vigencia_inicial' dateFormat="dd/mm/yy"
                                    :manualInput='false')

                        .p-col-12.p-md-4
                            label.form-label Data Final de Vigência:
                            .p-inputgroup
                                Calendar( disabled v-model='modelContrato.dt_vigencia_final' dateFormat="dd/mm/yy"
                                    :manualInput='false')

                        .p-col-12.p-md-4
                            label.form-label Tipo de contrato:
                            Dropdown(v-model='modelContrato.ie_tipo_contrato' :options='options.ie_tipo_contrato'
                                optionLabel='label' optionValue='value' placeholder='Selecione' disabled)


                        .p-col-12.p-md-4
                            label.form-label Nº de vidas contempladas:
                            InputNumber(disabled v-model='modelContrato.nr_vidas_contempladas' mode='decimal')

                        .p-col-12.p-md-4
                            label.form-label Tipo de valor de intermediação:
                            Dropdown(disabled v-model='modelContrato.ie_tipo_valor' :options='options.ie_tipo_valor'
                                optionLabel='label' optionValue='value' placeholder='Selecione')
                        

                        .p-col-12.p-md-4
                            label.form-label Tipo de Mensalidade:
                            Dropdown(v-model='tipo_mensalidade' :options='options.tipo_mensalidade' disabled
                                optionLabel='text' optionValue='value' placeholder='Selecione')

                        .p-col-12.p-md-4(v-if='tipo_mensalidade == 0')
                            label.form-label Valor Mensalidade:
                            CurrencyInput(
                                disabled
                                v-model='modelContrato.vl_mensalidade'
                                locale='pt-BR'
                                :auto-decimal-mode='true'
                                :value-range='{ min: 0 }'
                        )
                        .p-col-12.p-md-4(v-else)
                            label <br>
                            div.ta-center
                                Button.p-button(
                                    type='button'
                                    icon="jam jam-plus"
                                    label="Ver Valores por faixa"
                                    @click="dialogFaixa = true")

                        .p-col-12.p-md-4(v-if="modelContrato.ie_tipo_valor == 'VL'" )
                            label.form-label Valor Intermediação:
                            CurrencyInput(
                                disabled v-model='modelContrato.nr_valor_intermediacao'
                                locale='pt-BR'
                                :auto-decimal-mode='true'
                                :value-range='{ min: 0 }'
                            )

                        .p-col-12.p-md-4(v-else)
                            label.form-label Valor Intermediação:
                            InputNumber(
                                suffix="%"
                                disabled v-model='modelContrato.nr_percentual_intermediacao'
                                :min='0'
                                :max='100'
                            )

                        .p-col-12.p-md-4
                            label.form-label Valor Mensalidade:
                            CurrencyInput(
                                disabled v-model='modelContrato.vl_mensalidade'
                                locale='pt-BR'
                                :auto-decimal-mode='true'
                                :value-range='{ min: 0 }'
                            )

                        .p-col-12.p-md-4
                            label.form-label Dt. vencimento da mensalidade:
                            InputNumber(
                                disabled v-model='modelContrato.nr_dia_vencimento_mensalidade'
                                :min='1'
                                :max='31'
                            )

                        .p-col-12.p-md-4
                            label.form-label Dt. vencimento da regate:
                            InputNumber(
                                disabled v-model='modelContrato.nr_dia_vencimento_resgate'
                                :min='1'
                                :max='31'
                            )

                        .p-col-12.p-md-4
                            label.form-label Dt. vencimento de taxa:
                            InputNumber(
                                disabled v-model='modelContrato.nr_dia_vencimento_taxa'
                                :min='1'
                                :max='31'
                            )

                        .p-col-12.p-md-4
                            label.form-label Contempla dependentes:
                            InputSwitch( disabled v-model='modelContrato.ie_contempla_dependentes')

                        .p-col-12.p-md-12
                            label.form-label Observações:
                            Textarea(type='text' disabled v-model='modelContrato.ds_observacao'
                                style='width: 100%; padding: 5px'
                                :autoResize="true"
                                rows="4"
                                )
                TabView.mt-2(@tab-change='onTabChange')

                    TabPanel(:header="`Lista de ${model.cd_tipo_empresa === 1 ? 'clientes' : 'correntistas'}`" v-bind:telaVisivel="'LC'" :active="telaVisivel === 'LC'" @click='telaVisivel = "LS"')
                    TabPanel(v-if='isAdm' header='Lista de Solicitações' v-bind:telaVisivel="'LS'" :active="telaVisivel === 'LS'" @click="telaVisivel='LS'")
                    Panel.mb-2( v-if="telaVisivel == 'LC'" header='Filtros' :toggleable='true' )
                        div
                            .p-grid.p-fluid.p-align-start
                                .p-col-4
                                    label.form-label Nome:
                                    .p-inputgroup
                                        InputText(type='text'  v-model='filters.nm_pessoa_fisica' @keyup.enter="filtrarAssociados()")
                                        Button.p-button-danger(
                                            v-if='filters.nm_pessoa_fisica.length'
                                            icon='jam jam-rubber'
                                            @click="filters.nm_pessoa_fisica = ''"
                                        )

                                .p-col-3
                                    label.form-label CPF:
                                    .p-inputgroup
                                        InputMask(
                                            :mask="'999.999.999-99'"
                                            v-model="filters.nr_cpf"
                                            @keydown.enter="filtrarAssociados()"
                                            :autoClear="false")
                                        Button.p-button-danger(
                                            v-if='filters.nr_cpf.length'
                                            icon='jam jam-rubber'
                                            @click="filters.nr_cpf = ''")

                                .p-col-3
                                    label.form-label Motivo de inativação:
                                    Dropdown(v-model='filters.ie_motivo' :options='options.ie_motivo'
                                        optionLabel='label' optionValue='value' @change="filtrarAssociados()" placeholder='Selecione')

                                .p-col-2
                                    label.form-label Status:
                                    Dropdown(v-model='filters.ie_status' :options='options.ie_status'
                                        optionLabel='text' optionValue='value' @change="filtrarAssociados()" placeholder='Selecione')


                                .p-col-5
                                    label.form-label Inclusão de Correntista:
                                    .p-inputgroup
                                        Dropdown(
                                            v-model='tipo_data_inclusao'
                                            :options='options.tipo_data'
                                            optionLabel='label'
                                            optionValue='value_inc'
                                            dataKey='value_inc'
                                            :style="{'width':'30%'}"
                                        )
                                        Calendar(
                                            v-model="filters[tipo_data_inclusao]"
                                            :dateFormat="tipo_data_inclusao == 'dt_competencia_inclusao' ? 'mm/yy' : 'dd/mm/yy'"
                                            :placeholder="tipo_data_inclusao == 'dt_competencia_inclusao' ? 'MM/AAAA' : 'DD/MM/AAAA'"
                                            :view="tipo_data_inclusao == 'dt_competencia_inclusao' ? 'month' : 'date'"
                                            :selectionMode="tipo_data_inclusao == 'dt_competencia_inclusao' ? 'single' : 'range'"
                                            :locale="pt_br"
                                            :monthNavigator="true"
                                            :yearNavigator="true"
                                            :manualInput="true"
                                            :yearRange="`2020:${esseAno}`"
                                            :style="{'width':'70%'}"
                                            @keydown.enter="filtrarAssociados()"
                                        )
                                        Button.p-button-danger(
                                            v-if="filters[tipo_data_inclusao]"
                                            icon='jam jam-rubber'
                                            @click="filters[tipo_data_inclusao] = null"
                                        )
                                .p-col-5

                                    label.form-label Período de Atividade:
                                    .p-inputgroup
                                        Dropdown(
                                            v-model='tipo_data_atividade'
                                            :options='options.tipo_data'
                                            optionLabel='label'
                                            optionValue='value_atv'
                                            dataKey='value_atv'
                                            :style="{'width':'30%'}"
                                        )
                                        Calendar(
                                            v-model="filters[tipo_data_atividade]"
                                            :dateFormat="tipo_data_atividade == 'dt_competencia' ? 'mm/yy' : 'dd/mm/yy'"
                                            :placeholder="tipo_data_atividade == 'dt_competencia' ? 'MM/AAAA' : 'DD/MM/AAAA'"
                                            :view="tipo_data_atividade == 'dt_competencia' ? 'month' : 'date'"
                                            :selectionMode="tipo_data_atividade == 'dt_competencia' ? 'single' : 'range'"
                                            :locale="pt_br"
                                            :monthNavigator="true"
                                            :yearNavigator="true"
                                            :manualInput="true"
                                            :yearRange="`2020:${esseAno}`"
                                            :style="{'width':'70%'}"
                                            @keydown.enter="filtrarAssociados()"
                                        )
                                        Button.p-button-danger(
                                            v-if='filters[tipo_data_atividade]'
                                            icon='jam jam-rubber'
                                            @click="filters[tipo_data_atividade] = null"
                                        )

                                .p-col-2
                                    label <br>
                                    Button(
                                        label='Filtrar'
                                        icon='jam jam-search'
                                        :disabled='waitingAssociados'
                                        @click="filtrarAssociados()"
                                        :style="{'cursor':waitingAssociados ? 'wait' : 'pointer'}")
                                .p-col-12.ta-right
                                    Button(
                                        style="margin-right: flex-end;max-width:20%;margin-left: 10px"
                                        label='Limpar Filtros'
                                        icon='jam jam-rubber'
                                        @click="limparFiltros()")

                                .p-col-12.ta-right(v-if='!isAdd')
                                    Button.p-button-success(
                                        style="margin-right: flex-end;max-width:20%;"
                                        icon="jam jam-attachment"
                                        label="Inserir arquivo de correntistas"
                                        @click="$refs.arquivoCorrentistas.click()")
                                    Button(
                                        label='Adicionar correntista'
                                        icon='jam jam-plus'
                                        style='max-width:15.8%; margin-left: 10px'
                                        @click="isAdd = true")


                                .p-col-5.buscar-paciente(:class="{ naoVer: !isAdd}")
                                    BuscarPaciente(ref='buscarPaciente' label='Cliente' :cancelarPopup='true' @change='clienteAlterado = true' @limpa='clienteAlterado = false; isAdd = false;'  @busca='clienteAlterado = true')

                                .p-col-2(v-if="isAdd")
                                    label.form-label Aporte:
                                    CurrencyInput(
                                        v-model='valorAporte'
                                        locale='pt-BR'
                                        :auto-decimal-mode='true'
                                        :value-range='{ min: 0 }'
                                    )

                                .p-col-3(v-if="isAdd")
                                    label <br>
                                    div.ta-center
                                        Button.p-button(
                                            v-if='!arquivoContrato'
                                            icon="jam jam-attachment"
                                            label="Inserir Termo"
                                            @click="$refs.arquivoContrato.click()")

                                        .p-inputgroup(v-else)
                                            InputText(type="text" placeholder="Anexar comprovante" v-model="arquivoContrato[0].name"
                                                @click="$refs.arquivoContrato.click()" readonly)
                                            Button.p-button(icon='jam jam-attachment' @click="$refs.arquivoContrato.click()" v-tooltip.top="'Anexar'")

                                .p-col-2(v-if="isAdd && !waitingAssociados")
                                    label <br>
                                    div.ta-center
                                        ProgressSpinner(v-if='waitingAddC' strokeWidth='4')
                                        Button(
                                            v-else
                                            label='Vincular Correntista'
                                            icon='jam jam-plus'
                                            :disabled='!clienteAlterado'
                                            @click="addCorrentista()")

                                .p-col-12(v-if="isAdd")
                                    .ta-right 
                                        Button(
                                            label='Cancelar'
                                            style='width:16%'
                                            icon='jam jam-close'
                                            @click="isAdd = false; clienteAlterado = false;")

                                .p-col-3
                                   label.form-label Relatório:
                                   ProgressBar(v-if='waitingRelatorio' mode="indeterminate")
                                   .p-inputgroup(v-else)
    
                                       Button.p-button-info(
                                           :disabled="waitingRelatorio || waitingAssociados"
                                           label='CSV'
                                           v-tooltip.top="'Solicitar em formato CSV'"
                                           icon='jam jam-printer'
                                           @click="imprimirAssociados('csv')")
    
                                       Button.p-button-info(
                                           :disabled="waitingRelatorio || waitingAssociados"
                                           label='PDF'
                                           v-tooltip.top="'Solicitar em formato PDF'"
                                           icon='jam jam-printer'
                                           @click="imprimirAssociados('pdf')")
    
                                       Button.p-button-warning(
                                           :disabled="waitingRelatorio || waitingAssociados"
                                           label='Checar Relatórios'
                                           icon='jam jam-search'
                                           @click="openDialogRelatorios()")


                            input(
                                v-show='false'
                                type="file"
                                ref="arquivoContrato"
                                @change="anexarContrato()"
                                accept=".pdf")
                            input(
                                v-show='false'
                                type="file"
                                ref="arquivoCorrentistas"
                                @change="anexarCorrentistas()"
                                accept=".xlsx")

                        ProgressBar(v-if='waitingAssociados' mode="indeterminate")

                        div(v-else-if="!listaAssociados.length")
                            p.ta-center Nenhum item encontrado

                        div(v-else)
                            DataTable(:value='totalizadores')
                                Column(
                                    v-for='t in tableData'
                                    :key="t.header"
                                    :headerStyle='t.hStyle'
                                    :header='t.header'
                                    :field='t.field'
                                    :bodyStyle='t.bStyle'
                                    :sortable='t.sort'
                                )
                                    template(#body='{data}')
                                        b(v-if="t.field == 'count'") {{ data[t.field] }}
                                        b(v-else)
                                            span(v-if="data?.saldo_remanescente\
                                                && t.field == 'total_rendido'\
                                                && !filters.nm_pessoa_fisica \
                                                && !filters.nr_cpf \
                                                && !filters.dt_competencia_inclusao\
                                                && !filters.dt_intervalo_inclusao")
                                                span {{ $root.formatPrice(data[t.field] + data.saldo_remanescente) }} 
                                                i.jam.jam-info(
                                                    style='color:blue'
                                                    v-tooltip.top="'Possui ' + $root.formatPrice(data.saldo_remanescente) + ' de ajuste de rendimento.'"
                                                )
                                            span(v-else) {{ $root.formatPrice(data[t.field]) }}

                            Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage")
                            DataTable(:value="listaAssociados")
                                Column(headerStyle="width: 16%;" field="nm_pessoa_fisica" header="Correntista")
                                    template(#body='{data}')
                                        b {{ data.nm_pessoa_fisica }}
                                        br
                                        em
                                            b CPF:
                                            span {{ data.nr_cpf_f }}
                                        br
                                        em
                                            b Tel.:
                                            span {{ data.nr_telefone_f }}
                                        br
                                        em
                                            b Sexo:
                                            span {{ data.ie_sexo_f }}
                                        .ta-right.my-2
                                            b.tag-restrito.ta-right(v-tooltip.top="'Usuário Inativo'" v-if="!data.ie_status") Inativo
                                        .ta-right.my-2
                                            b.tag-ae.ta-right(v-tooltip.top="'Uma solicitação de exlusão foi gerada para esse usuário'" v-if="data.ie_situacao == 'AE'") Solicitação de Exclusão
                                //- Column(headerStyle="width: 5%;" bodyClass="ta-center" field="ie_sexo" header="Sexo")
                                //- Column(headerStyle="width: 10%;" bodyClass="ta-center" field="nr_telefone_f" header="Telefone")
                                Column(headerStyle="width: 8%;" header="Aporte" bodyClass="ta-center" field="nr_valor_apurado_f")
                                Column(headerStyle="width: 8%;" header="Rendimento" bodyClass="ta-center" field="nr_valor_rendido_f")
                                Column(headerStyle="width: 8%;" header="Utilização" bodyClass="ta-center" field="nr_valor_utilizado_f")
                                Column(headerStyle="width: 8%;" header="Saldo" bodyClass="ta-center" field="nr_saldo_med_empresa_f")
                                Column(headerStyle="width: 7%;" header="Tipo" bodyClass="ta-center" field="cd_titular")
                                    template(#body="{data}")
                                        span {{ data.cd_titular? "Dependente" : "Titular" }}
                                Column(headerStyle="width: 8%;" bodyClass="ta-center" header="Dt. Inclusão" field="dt_inclusao_f")
                                Column(headerStyle="width: 8%; text-align: center" header="Ações")
                                    template(#body="{data}")
                                        .ta-center
                                            Button.p-button-raised.p-button-rounded.mr-1(
                                                v-tooltip.top="'Visualizar'"
                                                type="button"
                                                icon="jam jam-user"
                                                @click='setUser(data)'
                                            )
                                            Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
                                                v-if="data.ie_status"
                                                v-tooltip.top="'Inativar'"
                                                type="button"
                                                icon="jam jam-minus-circle"
                                                @click='dialogInativar = true; dialogInativar_data = data'
                                            )
                                            Button.p-button-raised.p-button-rounded.mr-1(
                                                v-tooltip.top="'Histórico de Ativação'"
                                                icon="jam jam-clock"
                                                @click="getHistorico(data)"
                                                style='background-color: rebeccapurple; border: none;'
                                            )

                                            //- Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
                                            //-     v-if='isAdm'
                                            //-     v-tooltip.top="'Apagar'"
                                            //-     icon="jam jam-minus-circle"
                                            //-     @click="dialogApagar = true; dialogApagar_data = data"
                                            //- )
                                            Button.p-button-raised.p-button-rounded.mr-1(
                                                v-tooltip.top="'Histórico de procedimentos'"
                                                type="button"
                                                icon="jam jam-newspaper"
                                                @click='setHistoricoDialog(data)'
                                            )

                            Paginator(:rows="paginator.per_page" :first="(paginator.page - 1) * paginator.per_page" :totalRecords="paginator.count" @page="onPage")
                    div(v-if="telaVisivel == 'LS'")
                        Panel.mb-2(header='Filtros' :toggleable='true')
                            .p-grid.p-fluid.p-align-end
                                .p-col-4
                                    label.form-label Nome:
                                    .p-inputgroup
                                        InputText(type='text'  v-model='filtersSolicitacoes.nm_correntista' @keyup.enter="filtrarSolicitacoes()")
                                .p-col-4
                                    label.form-label Tipo:
                                    Dropdown(v-model='filtersSolicitacoes.ie_tipo_status' :options='options.ie_tipo_status' showClear
                                        dataKey='value' optionLabel='label' optionValue='value' placeholder='Selecione' @keyup.enter="filtrarSolicitacoes()")
                                .p-col-4
                                    label.form-label Situação:
                                    Dropdown(v-model='filtersSolicitacoes.ie_situacao' :options='options.ie_situacao' showClear
                                        dataKey='value' optionLabel='label' optionValue='value' placeholder='Selecione' @keyup.enter="filtrarSolicitacoes()")
                                .p-col-4
                                    label.form-label Período:
                                    Calendar(
                                            v-model="filtersSolicitacoes.dt_inicial"
                                            placeholder="DD/MM/AAAA - DD/MM/AAAA"
                                            dateFormat="dd/mm/yy"
                                            selectionMode="range"
                                            :locale="pt_br"
                                            @keydown.enter="filtrarSolicitacoes()"
                                        )
                                        Button.p-button-danger(
                                            v-if="filters[tipo_data_inclusao]"
                                            icon='jam jam-rubber'
                                            @click="filters[tipo_data_inclusao] = null"
                                        )
                                .p-col-4
                                .p-col-2
                                    Button(
                                        label='Filtrar'
                                        icon='jam jam-search'
                                        @click="filtrarSolicitacoes()")
                                .p-col-2
                                    Button(
                                        label='Limpar Filtros'
                                        icon='jam jam-rubber'
                                        @click="limparFiltrosSolicitacoes()")

                        ProgressBar(v-if='waitingSolicitacoes' mode="indeterminate")
                        div(v-else-if='listSolicitacoes.length == 0')
                            .ta-center
                                p Nenhuma solicitação encontrada
                        div(v-else)
                            Paginator(:rows="paginatorSolicitacoes.per_page" :first="(paginatorSolicitacoes.page - 1) * paginatorSolicitacoes.per_page" :totalRecords="paginatorSolicitacoes.count" @page="onPageSolicitacoes")
                            DataTable( :value="listSolicitacoes")
                                Column(headerStyle="width: 8%; text-align: center" bodyStyle="text-align: center" field="dt_criado_f" header="Data da Solicitacão")
                                Column(headerStyle="width: 8%; text-align: center" bodyStyle="text-align: center" field="associado" header="Correntista")
                                    template(#body='props')
                                        p {{ props.data.associado.nm_pessoa_fisica }}
                                Column(headerStyle="width: 8%; text-align: center" bodyStyle="text-align: center" field="ds_observacao" header="Observação")
                                    template(#body='props')
                                        p {{ props.data.associado.ds_observacao ? props.data.associado.ds_observacao : '-' }}
                                Column(headerStyle="width: 8%; text-align: center" bodyStyle="text-align: center" field="ie_motivo_f" header="Motivo")
                                Column(headerStyle="width: 8%; text-align: center" bodyStyle="text-align: center" field="ie_tipo_status_f" header="Tipo")
                                Column(headerStyle="width: 8%; text-align: center" bodyStyle="text-align: center" field="ie_situacao" header="Situação")
                                    template(#body='props')
                                        .cell(v-if="props.data.ie_situacao === 'PE'"
                                            :class="{pe: true}") <b> PENDENTE </b>
                                        .cell(v-if="props.data.ie_situacao === 'AP'"
                                            :class="{ap: true}") <b> APROVADO </b>
                                        .cell(v-if="props.data.ie_situacao === 'CA'"
                                            :class="{ca: true}") <b> RECUSADO </b>

                                Column(headerStyle="width: 8%; text-align: center" bodyStyle="text-align: center" header="Ações")
                                    template(#body="props")
                                        .ta-center
                                            Button.p-button-raised.p-button-rounded.p-button-success.mr-1(
                                                v-if='props.data.ie_situacao == "PE"'
                                                v-tooltip.top="'Aprovar Solicitacão'"
                                                @click='handleDialogAprovar(props.data)'
                                                type="button"
                                                icon="jam jam-check"
                                            )
                                            Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
                                                v-if='props.data.ie_situacao == "PE"'
                                                v-tooltip.top="'Cancelar Solicitacão'"
                                                @click='handleDialogCancelar(props.data)'
                                                type="button"
                                                icon="jam jam-close"
                                            )
                                            Button.p-button-raised.p-button-rounded.p-button-warning.mr-1(
                                                v-if='props.data.ie_situacao == "AP"'
                                                v-tooltip.top="'Anexar documento'"
                                                @click='handleDialogDocumento(props.data)'
                                                type="button"
                                                icon="jam jam-attachment"
                                            )

                                            Button.p-button-raised.p-button-rounded.mr-1(
                                                v-if='props.data.aq_anexo_url != null'
                                                v-tooltip.top="'Visualizar documento'"
                                                @click='visualizarComprovanteAprovarSolicitacao(props.data.aq_anexo_url)'
                                                type="button"
                                                icon="jam jam-document"
                                            )
                            Paginator(:rows="paginatorSolicitacoes.per_page" :first="(paginatorSolicitacoes.page - 1) * paginatorSolicitacoes.per_page" :totalRecords="paginatorSolicitacoes.count" @page="onPageSolicitacoes")
</template>

<style lang="scss">
.empresa-visualizar {
    .dialogFaixa{
        width:60%
    }
    .p-progress-spinner {
        width: 30px;
        height: auto;
    }
    .dialogHistorico {
			width:60%
	}
    .tag-restrito {
			background-color: #e91224;
			color: white;
			padding: 2%;
			border-radius: 5px;
	}
    .tag-ae {
			background-color: #fffc67;
			color: rgb(78, 78, 78);
			padding: 2%;
			border-radius: 5px;
	}

    .dialogRelatorios {
        width: 70%;
        max-width: 95%;
    }
    .dialogApagar {
        .p-progress-spinner {
            width: 29px;
            height: auto;
        }
    }
    .dialogErros {
        width:60%;
        overflow: auto;
        .p-progress-spinner {
            width: 29px;
            height: auto;
        }
    }
    .dialogInativar {
        width: 40%;
        .p-progress-spinner {
            width: 29px;
            height: auto;
        }
    }
    .buscar-paciente{
        // max-width:0%;
        &.naoVer {
            display: none;
        }
    }
        .p-disabled {
            opacity: 1;
        }
        .p-component:disabled{
            opacity: 1;
        }
    .cell {
        padding: 16px 0;
        text-align: center;
        height:70px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.pe {
            background-color: #fff1cc;//amarelado
        }
        &.ap {
            background-color: #daf2c2;//verde
        }
        &.ca {
            background-color: #f79c9c;//vermelho-claro
        }
    }
    .dialogAprovar{
        width: 40%;
        .p-dialog-content {
            overflow: visible !important;
        }
    }
    .dialogCancelar{
        width: 40%;
        .p-dialog-content {
            overflow: visible !important;
        }
    }
}
</style>

<script>
    import Button from "primevue/button";
    import Column from "primevue/column";
    import Calendar from "primevue/calendar";
    import Dropdown from "primevue/dropdown";
    import DataTable from "primevue/datatable";
    import InputMask from "primevue/inputmask";
    import InputText from "primevue/inputtext";
    import Paginator from "primevue/paginator";
    import Panel from "primevue/panel";
    import TabView from 'primevue/tabview'
    import TabPanel from 'primevue/tabpanel'
    import ProgressBar from "primevue/progressbar";
    import Tooltip from "primevue/tooltip";
    import MostrarPaciente from "./MostrarPaciente"
    import DialogHistoricoCorrentista from "./DialogHistoricoCorrentista.vue"
    import Dialog from 'primevue/dialog'
    import ProgressSpinner from 'primevue/progressspinner'
    import BuscarPaciente from './../Paciente/BuscarPaciente'
    import CustomDataTable from "../CustomComponents/CustomDataTable.vue";
    import InputNumber from 'primevue/inputnumber'
    import InputSwitch from 'primevue/inputswitch';
    import Textarea from 'primevue/textarea'
    import CurrencyInput from "@/components/CustomComponents/CurrencyInput";

    import moment from "moment";
    import { Empresa, DominioValor, Paciente, Relatorio} from "./../../middleware";
    import { pCalendarLocale_ptbr } from './../../utils';
    import wsConfigs from "./../../middleware/configs";
    import { fixTelefoneFormat, formatDateToBR } from "@/utils";

    const _ = require('lodash');

    export default {
        components: { Button, Column, DataTable, InputMask, InputText, Dialog, ProgressSpinner, InputNumber,  TabView, TabPanel,
            Paginator, Panel, ProgressBar, Tooltip, MostrarPaciente, BuscarPaciente, DialogHistoricoCorrentista,
            CustomDataTable, Calendar, Dropdown, InputSwitch, Textarea, CurrencyInput },
        directives: { tooltip: Tooltip },
        watch: {
            "$refs.buscarPaciente": function(value){
                this.cliente = value.model;
            },
            tipo_data_inclusao: function (novo, velho) {
            this.filters[velho] = null
            },
            tipo_data_atividade: function (novo, velho) {
                this.filters[velho] = null
            }
        },
        created () {
            let id = parseInt(this.$route.params.id)
            if (!isNaN(id)) this.getEmpresa(id)
            if(this.$route.path.includes("adm")) {
                this.isAdm = true
                Promise.all([this.getDominios()]).then(() => {
                        this.getContrato()
                    })
            }
        },
        data () {
            return {
                model: {
                    id: null,
                    cd_estado: null,
                    ds_tipo_empresa: null,
                    ds_motivo_suspensao: null,
                    cd_pessoa_juridica: {
                        id: null,
                        nr_cnpj: null,
                        nr_cnpj_f: null,
                        nm_fantasia: null,
                        nr_inscricao_estadual: null,
                        nr_inscricao_municipal: null,
                        ie_tipo_pessoa_juridica: null,
                        ds_observacao: null,
                        dt_atualizado: null,
                        nm_usuario_edi: null,
                        ds_razao_social: null,
                        enderecos: null,
                        contatos: [],
                        bancos: [],
                        tributos: [],
                        planos_contas: [],
                        cnaes: [],
                    },
                    
                    nm_razao_social: null,
                    nm_fantasia: null,
                    nr_cnpj: null,
                    nr_inscricao_municipal: null,
                    nr_inscricao_estadual: null,
                    ie_iss_retido: null,
                    nr_telefone: null,
                    nr_whatsapp: null,
                    ds_observacao: null,
                    aq_foto: null,
                    nm_rua: null,
                    ds_complemento: null,
                    nm_bairro: null,
                    nr_cep: null,
                    nr_empresa: null,
                    nr_latitude: null,
                    nr_longitude: null,
                    ds_email: null,
                    nm_responsavel: null,
                    ie_status: null,
                    nm_usuario_cri: null,
                    nm_usuario_edi: null,
                    dt_criado: null,
                    dt_atualizado: null,
                    id_externo: null,
                    cd_cidade: null,
                    cd_tipo_empresa: null,

                },
                isAdm: false,
                telaVisivel: 'LC',
                dialogRelatorios: false,
                dialogRelatorios_data: null,
                waitingRelatorio: false,
                waitingRelatorioListar: false,
                dialogApagar: false,
                dialogApagar_data: null,
                waitingApagar: false,
                dialogHistorico: false,
                dialogHistorico_data: {},
                dialogInativar: false,
                dialogInativar_data: {},
                modelInativar: {},
                waitingInativar: false,
                dialogHistoricoCorrentista: {
                    visible: false,
                    data: null
                },
                dialogAprovar: {
                    visible: false,
                    data: null
                },
                dialogCancelar: {
                    visible: false,
                    data: null
                },
                dialogDocumento: {
                    visible: false,
                    data: null
                },
                dialogUser: {
                    visible: false,
                    data: null
                },
                tipo_correntista: [
                    { value: "T", label: "Titular"},
                    { value: "F", label: "Filho"}
                ],
                modelUser: {},
                filters: {
                    nm_pessoa_fisica: '',
                    nr_cpf: '',
                    dt_competencia_inclusao: null,
                    dt_intervalo_inclusao: null,
                    dt_competencia: null,
                    dt_intervalo: null,
                    ie_status: 'true',
                    ie_motivo: null
                },
                filtersSolicitacoes: {
                    ie_tipo_status: null,
                    ie_situacao: null,
                    nm_correntista: '',
                    dt_inicial: null,
                    dt_final: null
                },
                options: {
                    tipo_data: [
                        { value_atv: "dt_competencia", value_inc: 'dt_competencia_inclusao', label: "Competência"},
                        { value_atv: "dt_intervalo", value_inc: 'dt_intervalo_inclusao', label: "Intervalo"}
                    ],
                    ie_tipo_contrato: [],
                    ie_tipo_valor: [],
                    ie_tipo_status: [],
                    ie_acao: [],
                    ie_situacao: [],
                    ie_status: [
                        { text: 'TODOS', value: null },
                        { text: 'Ativo', value: 'true' },
                        { text: 'Inativo', value: 'false' },
                    ],
                    ie_motivo:[],
                    tipo_acao: [],
                    tipo_mensalidade: [
                        { text: 'Valor Único', value: 0 },
                        { text: 'Por faixa Étaria', value: 1 }
                    ]
                },
                tipo_mensalidade: 0,
                tipos_acao: [],
                pt_br: pCalendarLocale_ptbr,
                tipo_data_inclusao: "dt_competencia_inclusao",
                tipo_data_atividade: "dt_competencia",
                esseAno: parseInt(moment().format("YYYY")),
                isAdd: false,
                cliente: null,
                waiting: true,
                waitingAssociados: false,
                waitingPaciente: false,
                waitingHistorico: false,
                waitingAprovar: false,
                waitingSolicitacoes: false,
                waitingComprovanteAprovarSolicitacao: false,
                selectedPai: null,
                waitingAddC: false,
                listaAssociados: [],
                listSolicitacoes: [],
                clienteAlterado: false,
                clienteSelecionado:false,
                comprovanteAprovar: {},
                comprovanteDocumento: {},
                dialogFaixa: false,
                faixasEtarias: [],
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                modelContrato: {},
                paginatorSolicitacoes: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                valorAporte: 0,
                arquivoContrato: null,
                params: {},
                totalizadores: [],
                dialogErros:false,
                erros: {
                    erros_correntista: [],
                    erros_planilha: []
                },
                totalAcertos: 0,
                totalErros: 0,
                tableData: [
                    {
                        header: 'Total Correntistas',
                        field: 'count',
                        hStyle: {
                            width:'15%'
                        },
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
                    {
                        header: 'Total Aportes',
                        field: 'total_apurado',
                        hStyle: {
                            width:'20%'
                        },
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
                    {
                        header: 'Total Rendimentos',
                        field: 'total_rendido',
                        hStyle: {
                            width:'20%'
                        },
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
                    {
                        header: 'Total Utilizado',
                        field: 'total_usado',
                        hStyle: {
                            width:'20%'
                        },
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
                    // {
                    //     header: 'Total Devolvido',
                    //     field: 'total_devolvido',
                    //     hStyle: {
                    //         width:'20%'
                    //     },
                    //     bStyle: {
                    //         textAlign: 'center'
                    //     }
                    // },
                    {
                        header: 'Saldo Atual',
                        field: 'saldo_atual',
                        hStyle: {
                            width:'20%'
                        },
                        bStyle: {
                            textAlign: 'center'
                        }
                    },
                ]
            }
        },
        methods: {
            _getEndereco() {
                // console.log(this.model.cd_pessoa_juridica.enderecos)
                if (this.model?.cd_pessoa_juridica?.enderecos?.length) {
                    return this.model.cd_pessoa_juridica.enderecos[0]
                }
                return {
                    nr_cep: null,
                    nm_logradouro: null,
                    nr_numero: null,
                    ds_complemento: null,
                    nm_bairro: null,
                }
            },
            _getContato() {
                // console.log(this.model.cd_pessoa_juridica.contatos)
                if (this.model?.cd_pessoa_juridica?.contatos?.length) {
                    return this.model.cd_pessoa_juridica.contatos[0]
                }
                return {
                    nr_telefone: null,
                    nm_contato: null,
                    nr_celular: null,
                    nr_whatsapp: null,
                    ds_email: null,
                }
            },
            formatPrice (val) {
                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
            },
            onPage (ev) {
                this.filtrarAssociados(ev.page + 1);
            },
            onPageSolicitacoes(ev) {
                this.filtrarSolicitacoes(ev.page + 1);
            },
            filtrarAssociados(page, force=false) {
                if (!this.model.id) return;

                this.paginator.page = page || 1;
                let params = {
                    cd_empresa: this.model.id,
                    paginacao: true,
                    page: this.paginator.page,
                    per_page: this.paginator.per_page,
                    ie_associado_med_empresa: true
                };
                Object.keys(this.filters).forEach(key => {
                    if (this.filters[key]) {
                        if (key === 'nr_cpf')
                            params[key] = this.filters[key].replace(/(\.|-|_)/g, '');
                        else if (key.startsWith('dt_')) {
                            var aux = _.isArray(this.filters[key])
                                        ? this.filters[key][1]
                                            ? this.filters[key]
                                            : [this.filters[key][0]]
                                        : [this.filters[key]]
                            const tipo = 'tipo_data_' + key.includes('intervalo') ? 'intervalo' : 'atividade'

                        params[key] = _.map(aux,
                                d => moment(d).format(
                                    this[tipo] == 'dt_competencia'
                                        ? 'YYYY-MM'
                                        : 'YYYY-MM-DD'))
                        } else
                            params[key] = this.filters[key];
                    }
                });
                // Fake loading quando os filtros forem os mesmos
                if (!force && _.isEqual(this.params, params)) {
                    if (!this.waitingAssociados) {
                        this.waitingAssociados = true;
                        setTimeout(() => this.waitingAssociados = false, 300);
                    }
                } else {
                    this.params = Object.assign({}, params);
                    this.getAssociados(params);
                }
            },
            getPaciente(cd_pessoa_fisica) {
                this.waitingPaciente = true;
                Paciente.find(cd_pessoa_fisica, { cd_empresa: this.model.id, ie_associado_med_empresa: true }).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        this.waitingPaciente = false;
                        this.modelUser = response.data
                        if(this.modelUser.cd_cidade){
                            this.modelUser.nm_estado = this.modelUser.cd_cidade.cd_estado.nm_estado,
                            this.modelUser.nm_cidade = this.modelUser.cd_cidade.nm_cidade
                        }
                        if (this.modelUser.nr_cpf) {
                            this.modelUser.nr_cpf_f = this.modelUser.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                            this.modelUser.nr_cpf = this.modelUser.nr_cpf_f
                        }
                        this.modelUser.dt_nascimento_f = moment(this.modelUser.dt_nascimento).format('DD/MM/YYYY')
                        this.modelUser.ie_sexo_f = this.modelUser.ie_sexo == 'M' ? 'Masculino' : 'Feminino'
                        this.modelUser.label = this.modelUser.nr_cpf ? `${ this.modelUser.nm_pessoa_fisica } (CPF: ${ this.modelUser.nr_cpf_f })` :
                            `${ this.modelUser.nm_pessoa_fisica } (Data nasc.: ${ this.modelUser.dt_nascimento_f })`
                        if (this.onSelectPessoaFisica) this.onSelectPessoaFisica()
                    }
                    else{
                        this.$toast.error("Paciente não encontrado")
                    }
                })
            },
            getAssociados(params){

                this.waitingAssociados = true

                Empresa.listarAssociados(params).then( response => {
                    this.waitingAssociados = false
                    if (response.status === 200) {
                        response.data.results.forEach(paciente => {
                            paciente.nm_pessoa_fisica = paciente.cd_titular ? `${paciente.nm_pessoa_fisica} (dependente)` : paciente.nm_pessoa_fisica
                            paciente.dt_nascimento_f = moment(paciente.dt_nascimento).format('DD/MM/YYYY')
                            paciente.dt_inclusao_f = moment(paciente.dt_inclusao).format('DD/MM/YYYY')
                            paciente.ie_sexo_f = ({ M: 'Masculino', F: 'Feminino' })[paciente.ie_sexo]
                            if (paciente.nr_telefone) paciente.nr_telefone_f = fixTelefoneFormat(paciente.nr_telefone)
                            if (paciente.nr_cpf) paciente.nr_cpf_f = paciente.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                            paciente.nr_valor_apurado_f = this.formatPrice(paciente.nr_valor_apurado)
                            paciente.nr_valor_rendido_f = this.formatPrice(paciente.nr_valor_rendido)
                            paciente.nr_valor_utilizado_f = this.formatPrice(paciente.nr_valor_utilizado)
                            paciente.nr_saldo_med_empresa_f = this.formatPrice(paciente.nr_saldo_med_empresa)
                        })
                        this.listaAssociados = response.data.results

                        this.totalizadores = [_.omit(response.data, ['results'])]
                        this.paginator.count = response.data.count
                    }
                })
            },
            getEmpresa(id) {
                this.waiting = true;
                Empresa.find(id).then(response => {
                    if (response.status === 200) {
                        let keys = Object.keys(this.model);
                        keys.forEach(key => {
                            if(response.data[key])
                                this.model[key] = response.data[key]
                        });
                        
                        this.model.id = response.data.id;
                        this.model.empresa = response.data.empresa
                        this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm');
                        if(this.model.cd_pessoa_juridica?.nr_cnpj) this.model.cd_pessoa_juridica.nr_cnpj_f = this.model.cd_pessoa_juridica.nr_cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
                        if (response.data.dt_atualizado) {
                            this.model.dt_atualizado_f = moment(response.data.dt_atualizado).format('DD/MM/YYYY HH:mm');
                        }
                        if (this.model.cd_pessoa_juridica.enderecos) {
                            this.model.cd_pessoa_juridica.enderecos = this.model.cd_pessoa_juridica.enderecos.map(endereco => {
                                endereco.nr_cep_f = endereco.nr_cep.replace(/(\d{5})(\d{3})/, '$1-$2');
                                return endereco
                            })
                        }
                        if (this.model.cd_pessoa_juridica.contatos) {
                            this.model.cd_pessoa_juridica.contatos = this.model.cd_pessoa_juridica.contatos.map(contato => {
                                contato.nr_telefone_f = fixTelefoneFormat(contato.nr_telefone);
                                return contato
                            })
                        }
                        this.filtrarAssociados();
                        this.filtrarSolicitacoes();
                    }
                    this.waiting = false;
                })
            },
            getDominios() {
                DominioValor.findAll({ds_mnemonico: ['TABELA_FAIXA_ETARIA', 'SITUACAO_CORRENTISTA', 'SITUACAO_HISTORICO_ASSOCIADO', 'MOTIVO_INATIVAR_CORRENTISTA', 'TIPO_STATUS_HISTORICO', 'TIPO_CONTRATO_EMPRESA', 'TIPO_VALOR_CONTRATO_MED_EMPRES']}).then(response => {
                    if(response.status === 200) {
                        response.data['SITUACAO_HISTORICO_ASSOCIADO'].valores.forEach(item => {
                            this.options.ie_situacao.push({value: item.ie_valor, label: item.ds_valor})
                        })
                        this.options.ie_motivo.unshift({value: null, label: 'TODOS'})
                        this.options.ie_motivo = response.data['MOTIVO_INATIVAR_CORRENTISTA'].valores.map(item => ({
                            value: item.ie_valor,
                            label: item.ds_valor
                        }))
                        // response.data['TIPO_STATUS_HISTORICO'].valores.forEach(item => {
                        //     this.options.ie_tipo_status.push({value: item.ie_valor, label: item.ds_valor})
                        // })
                        // response.data['SITUACAO_HISTORICO_ASSOCIADO'].valores.forEach(item => {
                        //     this.options.ie_tipo_status.push({value: item.ie_valor, label: item.ds_valor})
                        // })

                        response.data['TIPO_STATUS_HISTORICO'].valores.forEach(item => {
                            this.options.ie_tipo_status.push({value: item.ie_valor, label: item.ds_valor})
                        })
                        console.log(this.options.ie_tipo_status)
                        response.data['TIPO_CONTRATO_EMPRESA'].valores.forEach((un) => {
                            this.options.ie_tipo_contrato.push({ value: un.ie_valor, label: un.ds_valor})
                        });
                        this.options.ie_tipo_valor = response.data['TIPO_VALOR_CONTRATO_MED_EMPRES'].valores.map(item => ({
                            value: item.ie_valor,
                            label: item.ds_valor
                        }))
                        this.dominioFaixas = response.data['TABELA_FAIXA_ETARIA'].valores.map((item) => ({
                        value: item.ie_valor,
                        label: _.last(response.data['TABELA_FAIXA_ETARIA'].valores).id != item.id?
                            `${item.ds_valor.split(',')[0]} a ${item.ds_valor.split(',')[1]} anos de idade`:
                            `A partir de ${item.ds_valor.split(',')[0]} anos`,
                        nr_idade_inicial: +item.ds_valor.split(',')[0],
                        nr_idade_final: +item.ds_valor.split(',')[1],
                        nr_valor: 0,
                    }))
                    }
                })
            },
            filtrarSolicitacoes(page) {
                if (!this.model.id) return;

                this.paginatorSolicitacoes.page = page || 1;
                let params = {
                    cd_empresa: this.model.id,
                    order: 'id',
                    paginacao: true,
                    page: this.paginatorSolicitacoes.page,
                    per_page: this.paginatorSolicitacoes.per_page,
                };

                Object.keys(this.filtersSolicitacoes).forEach(key => {
                    if(this.filtersSolicitacoes[key] != null)
                        if (key == 'dt_inicial') {
                            [params['dt_inicial'], params['dt_final']] = this.filtersSolicitacoes[key]
                        } else params[key] = this.filtersSolicitacoes[key];
                })

                if( params['dt_inicial'])
                    params['dt_inicial'] = moment(params['dt_inicial']).format('YYYY-MM-DD')

                if( params['dt_final'])
                    params['dt_final'] = moment(params['dt_final']).format('YYYY-MM-DD')

                this.getSolicitacoesAdm(params)
            },
            getSolicitacoesAdm(params) {
                this.waitingSolicitacoes = true

                Empresa.listarSolicitacaoContratanteMedclub(params).then(response => {
                    if([200, 201, 203].includes(response.status)) {
                        // console.log("options", response)
                        this.listSolicitacoes = response.data.results
                        this.listSolicitacoes.forEach(solicitacao => {
                            solicitacao.ie_motivo_f = solicitacao.ie_motivo ? this.options.ie_motivo.find(i => i.value == solicitacao.ie_motivo)?.label : '-'
                            solicitacao.ie_situacao_f = solicitacao.ie_situacao ? this.options.ie_situacao.find(i => i.value == solicitacao.ie_situacao)?.label : '-'
                            solicitacao.ie_tipo_status_f = solicitacao.ie_tipo_status ? this.options.ie_tipo_status.find(i => i.value == solicitacao.ie_tipo_status)?.label : '-'
                            solicitacao.dt_criado_f = moment(solicitacao.dt_criado).format("DD/MM/YYYY HH:mm")
                        })
                        // console.log("BADALO", this.listSolicitacoes)
                        this.paginatorSolicitacoes.count = response.data.count
                    } else {
                        this.$toast.error(response.data.detail, 3000);
                    }
                    this.waitingSolicitacoes = false
                })
            },
            aprovarSolicitacoesAdm(){
                this.waitingAprovar = true
                let dataSend = {
                    cd_solicitacao: this.dialogAprovar.data.id,
                    ie_situacao: "AP"
                }
                Empresa.atualizarSolicitacaoContratanteMedclub(dataSend).then(response => {
                    if([200, 201, 203].includes(response.status)) {
                        this.listSolicitacoes = response.data
                        this.$toast.success("Solicitação aprovada com sucesso");
                        this.dialogAprovar.visible = false
                        this.dialogAprovar.data = null
                        //this.filtrarSolicitacoes()
                        this.filtrarSolicitacoes()
                    } else {
                        this.$toast.error(response.data.non_field_errors[0], 3000);
                    }
                    this.waitingAprovar = false
                })
            },
            cancelarSolicitacoesAdm(){
                this.waitingAprovar = true
                let dataSend = {
                    cd_solicitacao: this.dialogCancelar.data.id,
                    ie_situacao: "CA"
                }
                Empresa.atualizarSolicitacaoContratanteMedclub(dataSend).then(response => {
                    if([200, 201, 203].includes(response.status)) {
                        this.listSolicitacoes = response.data
                        this.$toast.success("Solicitação cancelada com sucesso");
                        this.dialogCancelar.visible = false
                        this.dialogCancelar.data = null
                        //this.filtrarSolicitacoes()
                        this.filtrarSolicitacoes()
                    } else {
                        this.$toast.error(response.data.non_field_errors[0], 3000);
                    }
                    this.waitingAprovar = false
                })
            },
            handleDialogDocumento(data) {
                this.comprovanteDocumento.name = data.aq_anexo_url
                this.dialogDocumento.visible = true
                this.dialogDocumento.data = data
            },
            handleDialogAprovar(data) {
                this.comprovanteAprovar.name = data.aq_anexo_url
                this.dialogAprovar.visible = true
                this.dialogAprovar.data = data
            },
            handleDialogCancelar(data) {
                this.dialogCancelar.visible = true
                this.dialogCancelar.data = data
            },
            handleCloseDialogDocument() {
                this.comprovanteDocumento = {}
                this.filtrarSolicitacoes()
            },
            anexarComprovanteAprovaSolicitacao() {
                // console.log("COMPROVANTE", this.$refs.comprovanteAprovarSolicitacao.files)
                const files = this.$refs.comprovanteAprovarSolicitacao.files;
                const comp = files.length && files[0];
                if (!comp) return;

                let cd_solicitacao

                if(this.dialogAprovar.visible) cd_solicitacao = this.dialogAprovar.data.id
                else cd_solicitacao = this.dialogDocumento.data.id

                let dataSend = {
                    aq_anexo: comp,
                    cd_solicitacao: cd_solicitacao,
                };

                this.waitingComprovanteAprovarSolicitacao = true;
                Empresa.anexarComprovanteAprovarSolicitacao(dataSend).then((response) => {
                    this.waitingComprovanteAprovarSolicitacao = false;
                    if (response.status === 200) {
                        if(this.dialogAprovar.visible) this.comprovanteAprovar = files[0];
                        else this.comprovanteDocumento = files[0]
                        this.$toast.success("Comprovante anexado com sucesso.");
                    } else if (response.data.detail) {
                        this.toastResponseDetail(response.data.detail);
                    } else {
                        this.toastResponseDetail("Falha ao enviar comprovante.");
                    }
                });
            },
            visualizarComprovanteAprovarSolicitacao(comprovante) {
                window.open(`${comprovante}` , '_blank')
            },
            setUser(data){
                this.getPaciente(data.id);
                this.selectedPai = data.cd_titular
                this.dialogUser.visible = true;
                this.dialogUser.data = data;
            },
            setHistoricoDialog(data) {
                this.dialogHistoricoCorrentista.visible = true
                this.dialogHistoricoCorrentista.data = data
            },
            excluirCorrentista() {
                let dataSend ={
                    cd_empresa: this.model.id,
                    correntistas: [this.dialogApagar_data.id]
                }
                this.waitingApagar = true;
                Empresa.excluirCorrentista(dataSend).then(response => {
                    this.waitingApagar = false;
                    if(([200,201]).includes(response.status)){
                        this.listaAssociados = this.listaAssociados.filter(item => item.id != this.dialogApagar_data.id);
                        this.$toast.success("Usuário excluido com sucesso");
                        this.dialogApagar_data = null;
                        this.dialogApagar = false;
                    }else if(response.status == 400){
                        this.$toast.error(response.data.detail, 6000);
                    }
                    else this.$toast.error("Erro na exclusão do Usuário");
                })

            },
            addCorrentista(){
                let dataSend = {
                    cd_empresa: this.model.id,
                    cd_pessoa_fisica: this.$refs.buscarPaciente.model.id,
                    nr_valor_aporte: this.valorAporte,
                    file_termo_adesao: this.arquivoContrato[0]
                }
                this.waitingAddC = true;
                Empresa.adicionarCorrentistasArquivo(dataSend).then(response => {
                    this.waitingAddC = false;
                    if(([200,201,204]).includes(response.status)){
                        this.listaAssociados.push({
                            id: this.$refs.buscarPaciente.model.id,
                            nm_pessoa_fisica: this.$refs.buscarPaciente.model.nm_pessoa_fisica,
                            nr_cpf_f: this.$refs.buscarPaciente.model.nr_cpf_f,
                            dt_nascimento_f: this.$refs.buscarPaciente.model.dt_nascimento_f,
                            ie_sexo_f: this.$refs.buscarPaciente.model.ie_sexo_f,
                            cd_pai: this.$refs.buscarPaciente.model.cd_pai,
                            cd_titular: this.$refs.buscarPaciente.model.cd_pai,
                            nr_saldo_med_empresa: 0,
                            nr_saldo_med_empresa_f: this.formatPrice(0),
                            ds_email: this.$refs.buscarPaciente.model.ds_email,
                            nr_telefone: this.$refs.buscarPaciente.model.nr_telefone,
                            ie_status: true
                        })
                        this.isAdd = false;
                        this.isAdd = false;
                        this.clienteAlterado = false;
                    }
                })
            },
            limparFiltros() {
                _.each(this.filters, (val, key) => {
                    if(_.isString(val))
                        this.filters[key] = ''
                    else
                        this.filters[key] = null
                })
                this.filtrarAssociados()
            },
            limparFiltrosSolicitacoes() {
                _.each(this.filtersSolicitacoes, (val, key) => {
                    this.filtersSolicitacoes[key] = ''
                })
                this.filtrarSolicitacoes()
            },
            onTabChange (ev) {
                this.telaVisivel = ev.tab.$attrs.telaVisivel
                if(this.telaVisivel == "ES")
                    // this.getList({ 'rede_medclub_empresa': true })
                    this.applyFilters()
            },
            inativarCorrentista(){
                if(!this.modelInativar.ie_motivo) {
                    this.$toast.error("Escolha um motivo de inativação")
                    return
                }

                let dataSend = {
                    cd_empresa: parseInt(this.$route.params.id),
                    correntistas: [this.dialogInativar_data.id],
                    ie_motivo: this.modelInativar.ie_motivo,
                    ds_observacao: this.modelInativar.ds_observacao,
                    // cd_associado: this.dialogInativar_data.cd_empresa_associado,
                }
                this.waitingInativar = true
                Empresa.inativarCorrentista(dataSend).then(response => {
                    this.waitingInativar = false
                    if(([200,201,204]).includes(response.status)){
                        this.$toast.success("Correntista inativado")

                        // this.filtrarAssociados(this.paginator.page)
                        this.listaAssociados.forEach(item => {
                            if(this.dialogInativar_data.id == item.id) item.ie_status = false
                        })
                        this.dialogInativar = false
                        this.dialogInativar_data = {}
                        this.modelInativar = {}
                    }
                })
            },
            getHistorico(data){
                this.dialogHistorico_data = data
                this.dialogHistorico = true
                this.waitingHistorico = true
                Empresa.historicoCorrentista(data.cd_empresa_associado).then(response => {
                    this.waitingHistorico = false
                    if(([200,201,204]).includes(response.status)){
                        this.dialogHistorico_data.list = response.data.map(item => ({
                            ...item,
                            dt_criado_f: moment(item.dt_criado).format('DD/MM/YY - HH:mm:ss')
                        }))
                        return
                    }
                    this.$toast.error("Erro na listagem de historico")
                })
            },
            anexarCorrentistas(){
                const files = this.$refs.arquivoCorrentistas.files
                const comp = files.length && files[0];
                if (!comp) return;
                let dataSend = {
                    cd_empresa: this.model.id,
                    file: files
                }
                this.waitingAssociados = true;
                Empresa.adicionarCorrentistasArquivo(dataSend).then(response => {
                    this.waitingAssociados = false;
                    if(([200,201,204]).includes(response.status)){
                        if(response.data.erros.nr_planilha > 0){
                            this.dialogErros = true
                            this.erros.erros_planilha = Object.keys(response.data.erros.erros_planilha).map(key => ({
                            text: `${key} - ${response.data.erros.erros_planilha[key]}`
                            }))
                        }
                        if(response.data.erros.nr_cadastro > 0){
                            this.dialogErros = true
                            this.erros.erros_correntista = Object.keys(response.data.erros.erros_correntista).map(key => ({
                            text: `CPF: ${key} - ${response.data.erros.erros_correntista[key]}`
                            }))
                        }
                        this.totalAcertos = response.data.cadastros.nr_total
                        this.totalErros = response.data.erros.nr_total

                        if(!this.dialogErros)
                            this.$toast.success("Correntistas adicionados com sucesso")
                            let params = {
                                cd_empresa: this.model.id,
                                paginacao: true,
                                page: this.paginator.page,
                                per_page: this.paginator.per_page,
                                ie_associado_med_empresa: true
                            };
                        this.getAssociados(params)
                        return
                    }
                    this.$toast.error(response?.data?.erros?.erros_planilha?.critical || "Planilha inconsistente")
                })
            },
            openDialogRelatorios() {
                this.dialogRelatorios = true
                this.waitingRelatorioListar = true
                let params = {
                    ie_situacao: "GE,PR,ER",
                    ds_relatorio: "Relação MedEmpresa - Correntistas",
                    dt_criacao: moment().format("YYYY-MM-DD"),
                    nr_relatorio: 47
                }
                Relatorio.getFilter(params).then(response => {
                    this.waitingRelatorioListar = false
                    if(([200,201,204]).includes(response.status)){
                        this.dialogRelatorios_data = response.data
                    } else {
                        this.dialogRelatorios_data = null
                        this.$toast.error("Erro na listagem de relatórios.");
                    }
                })
            },
            imprimirAssociados() {
                this.waitingRelatorio = true
                let dataSend = {
                    cd_relatorio: 47,
                    ie_tipo_relatorio: "csv",
                    sintetico: false,
                    nr_relatorio: 47,
                    cd_empresa: [this.model.id],
                    ids_associados: this.listaAssociados.map(item => (item.id))
                }
                Relatorio.emitirRelatorio(dataSend).then(response => {
                    this.waitingRelatorio = false
                    if(([200,201,204]).includes(response.status)){
                        this.openDialogRelatorios()
                        this.$toast.success("Relatório solicitado.");
                    } else {
                        this.$toast.error("Erro na solicitação de relatório.");
                    }
                })
            },
            formatDateToBR(date) {
                return formatDateToBR(date)
            },
            async downloadRelatorio(dataurl) {
                try {
                    window.open(dataurl, '_blank', 'noreferrer')
                } catch (error) {
                    this.$toast.error("Erro ao baixar o relatório:", error);
                }
            },
            anexarContrato(){
                const files = this.$refs.arquivoContrato.files
                const comp = files.length && files[0];
                if (!comp) return;
                this.arquivoContrato = files
            },
            downaloadContrato(){
                window.open(this.modelContrato.aq_anexo, '_blank', 'noreferrer')
            },
            getContrato(){
                Empresa.findAllContrato({cd_empresa: parseInt(this.$route.params.id)}).then(response => {
                    if(([200,201,204]).includes(response.status)){
                        this.getFaixas()
                        if(response.data.length > 0){
                            if(!response.data[0].vl_mensalidade) this.tipo_mensalidade = 1
                            this.modelContrato = {
                                ...response.data[0],
                                // dt_vigencia_inicial: moment(response.data[0].dt_vigencia_inicial, 'YYYY-MM-DD'),
                                vl_mensalidade: +response.data[0]?.vl_mensalidade,
                                dt_vigencia_inicial: moment(response.data[0].dt_vigencia_inicial)._d,
                                dt_vigencia_final: response.data[0].dt_vigencia_final? moment(response.data[0].dt_vigencia_final)._d : null,
                                nr_percentual_intermediacao: +response.data[0].nr_percentual_intermediacao,
                                nr_valor_intermediacao: +response.data[0].nr_valor_intermediacao
                            }
                        }
                    }
                })
            },
            getFaixas(){
                Empresa.getFaixas({ cd_empresa: parseInt(this.$route.params.id), ie_vigente: true }).then(response => {
                    if(([200,201,204]).includes(response.status)){
                        if(this.dominioFaixas.length == response.data.length){
                            this.faixasEtarias = response.data.map(item => ({
                                value: item.id,
                                label: _.last(response.data).id != item.id?
                                    `${item.nr_idade_inicial} a ${item.nr_idade_final} anos de idade`:
                                    `A partir de ${item.nr_idade_inicial} anos`,
                                nr_idade_inicial: item.nr_idade_inicial,
                                nr_idade_final: item.nr_idade_final,
                                nr_valor: item.nr_valor,
                            }))
                        }
                        else this.faixasEtarias = this.dominioFaixas
                        console.log("AQUI", this.faixasEtarias)
                        // console.log("aqui")
                        // console.log("AQUIIII",response.data)
                        // this.faixasEtarias = response?.data?.id ? response.data : this.dominioFaixas
                    }
                })

            },
           
            // getDominios(){
            //     DominioValor.findAll({ds_mnemonico: ['TIPO_CONTRATO_EMPRESA', 'TIPO_VALOR_CONTRATO_MED_EMPRES']}).then(response => {
            //     if (response.status == 200){
            //         response.data['TIPO_CONTRATO_EMPRESA'].valores.forEach((un) => {
            //                 this.options.ie_tipo_contrato.push({ value: un.ie_valor, label: un.ds_valor})
            //             });
            //         this.options.ie_tipo_valor = response.data['TIPO_VALOR_CONTRATO_MED_EMPRES'].valores.map(item => ({
            //             value: item.ie_valor,
            //             label: item.ds_valor
            //         }))
            //         }
            //     })
            // },
        }
    }
</script>
